<template>
  <Modal :is-open="isOpen" class="bg-black" @close="onModalClose">
    <header class="relative z-10 flex h-20 flex-none">
      <div class="container flex items-center justify-end">
        <button class="c-modal-action" @click="onModalClose">
          <span class="sr-only">Close video gallery</span>
          <UiIcon icon="close" size="md" />
        </button>
      </div>
    </header>
    <div class="container h-full px-0">
      <StoryblokComponent
        v-for="video in videos"
        :key="video._uid"
        :blok="video"
        class="flex h-full -translate-y-10 transform items-center justify-center px-0"
      />
    </div>
  </Modal>
</template>

<script setup lang="ts">
import type { BlockVideoStoryblok } from '@/types/storyblok'

defineProps<{
  isOpen: boolean
  videos: BlockVideoStoryblok[]
}>()

defineSlots<{
  content(): HTMLElement
}>()

const emit = defineEmits<{
  close: []
}>()

function onModalClose(): void {
  emit('close')
}
</script>

<style scoped lang="postcss">
.c-modal-action {
  @apply ease-out-expo text-white text-opacity-60 transition-colors duration-700 hover:text-opacity-100;

  &.is-active {
    @apply text-opacity-100;
  }
}
</style>
