<template>
  <div class="flex items-center gap-3">
    <UiButton
      v-if="
        bookInspectionButtonLink?.anchor || bookInspectionButtonLink?.cached_url
      "
      :link="bookInspectionButtonLink"
      variant="outline"
      theme="white"
      label="Book an inspection"
      left-icon="calendar"
      size="sm"
      @click="onBookInspection"
    />

    <UiButton
      :link="`tel:${phoneNumber}`"
      variant="fill"
      theme="white"
      label="Talk to us"
      left-icon="phone"
      size="sm"
    />
  </div>
</template>

<script setup lang="ts">
import type { MultilinkStoryblok } from '@/types/storyblok'

const emit = defineEmits<{
  close: []
}>()

const props = defineProps<{
  bookInspectionButtonLink?: MultilinkStoryblok
  agentPhone?: string
}>()
const { phone: corporatePhoneNumber } = await useConfigStore().asyncConfig()
const phoneNumber = computed(
  () => props.agentPhone ?? corporatePhoneNumber.value,
)

function onBookInspection() {
  emit('close')
}
</script>
